export const ID_SLOT_FREE = 13
export const ID_SLOT_PRO = 15
export const ID_SLOT_LITE = 17
export const ID_SLOT_STAFFING = 16
export const ID_SLOT_TO_SUCCESS = 14
export const ID_SLOT_QUOTATION = 18

export const slotList = [
	{
		id: ID_SLOT_FREE,
		name: 'Oferta gestionada manualmente',
		description: 'Gestionas manualmente los talentos recomendados por la IA.',
		qty_available: 0,
		order: 1,
	},
	{
		id: ID_SLOT_TO_SUCCESS,
		name: 'Oferta con pago al éxito',
		description: 'Nuestro servicio de headhunting encontrará el talento por tí.',
		qty_available: 0,
		order: 0,
	},
	{
		id: ID_SLOT_PRO,
		name: 'Oferta plan PRO',
		description: 'Recibes apoyo de un Recruiter que gestionará tus búsquedas.',
		qty_available: 0,
		order: 2,
	},
	{
		id: ID_SLOT_STAFFING,
		name: 'Oferta staffing',
		description: 'Contratamos los talentos para tu proyecto.',
		qty_available: 0,
		order: 4,
	},
	{
		id: ID_SLOT_LITE,
		name: 'Oferta plan Lite',
		description: '',
		qty_available: 0,
		order: 3,
	},
	{
		id: ID_SLOT_QUOTATION,
		name: 'Cotización',
		description: '',
		qty_available: 0,
		order: 5,
	},
]
