import validate from "/codebuild/output/src3903769023/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.4.2__ioredis@5.4.1__ahqawoffnrzgfjvt2i37eju4te/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45trailing_45slash_45global from "/codebuild/output/src3903769023/src/frontend-hire/middleware/01.trailing-slash.global.ts";
import _02_45global from "/codebuild/output/src3903769023/src/frontend-hire/middleware/02.global.ts";
import _03_45auth_45callbacks_45global from "/codebuild/output/src3903769023/src/frontend-hire/middleware/03.auth.callbacks.global.ts";
import _04_45auth_45global from "/codebuild/output/src3903769023/src/frontend-hire/middleware/04.auth.global.ts";
import _05_45user_45flow_45global from "/codebuild/output/src3903769023/src/frontend-hire/middleware/05.userFlow.global.ts";
import _06_45i18n_45global from "/codebuild/output/src3903769023/src/frontend-hire/middleware/06.i18n.global.ts";
import manifest_45route_45rule from "/codebuild/output/src3903769023/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.4.2__ioredis@5.4.1__ahqawoffnrzgfjvt2i37eju4te/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45trailing_45slash_45global,
  _02_45global,
  _03_45auth_45callbacks_45global,
  _04_45auth_45global,
  _05_45user_45flow_45global,
  _06_45i18n_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}