/**

 FEATURE FLAGS
 Documentación:https://www.notion.so/talently/Feature-Flags-996e45712ee244c48b4e3dc4098deab0

 Example
 export const REGULAR_SMART_SOURCING_HIRE_COMPANY_CANDIDATES_SEARCH_PAGE =
 'REGULAR_SMART_SOURCING_HIRE_COMPANY_CANDIDATES_SEARCH_PAGE'

 **/

/* -------------------- REGULAR -------------------- */
export const SPRINT_06_2024_TALENT_OPS_IMPROVE_OFFER_EXPERIENCE = 'sprint_06_2024_talent_ops_improve_offer_experience'
export const SQUAD_03_FREE_AIMED_SCHEDULING_CALLS = 'squad_03_20240724_free_aimed_scheduling_calls'
export const SQUAD_03_20240812_PROMOTIONAL_CODES = 'squad_03_20240812_promotional_codes_for_subscriptions'
export const SQUAD_01_20240903_MIGRATION_HIRE_REGISTER_CREATE_OFFER =
	'squad_01_20240903_migration_hire_register_create_offer'
export const SQUAD_01_20240924_HIRE_SEARCH_IMPROVES = 'squad_01_20240924_hire_search_improves'
export const SQUAD_02_NEW_FLOW_CANDIDATES = 'feature_flujo_de_candidatos'
export const FUSION_TALENT_PROFILE = 'squad_02_fusion_talent_profile'
export const INTEGRATE_FLOW_PLACEMENT = 'squad_02_integrate_flow_placement'
export const SQUAD_04_20241128_PLAN_SELECT_HIRE_REDESIGN_HOME = 'squad_04_20241128_plan_select_hire_redesign_home'

/* -------------------- MINIS -------------------- */
export const MINI_CANDIDATE_MANAGEMENT_LIMIT = 'mini_candidate_management_limit'
export const MINI_ADJUST_PLAN_CANCELLATION_REASONS = 'mini_adjust_plan_cancellation_reasons'
export const MINI_FEATURES_FOR_MY_OFFERS = 'mini_features_for_my_offers'
export const MINI_SEARCH_CANDIDATE_UPDATE = 'mini_search_candidate_update'
export const SQ2_AI_TOP_RECOMMEND = 'sq2_ai_top_recommend'
export const SQ3_FREE_LIMITS = 'sq3_free_limits'
export const SQ2_AUTOMATION_15_PAUSE_5_DISABLE = 'sq2_automation_15_pause_5_disable'
export const SKUS_IMPLEMENTATION = 'squad_03_skus_implementation'
export const FREE_WORK_OFFER_RESTRICTIONS = 'squad_04_20241129_add_free_work_offer_restrictions'
export const SQ2_PLAN_SELECT_PT2 = 'sq2_plan_select_pt2'

/* -------------------- BUGS -------------------- */

/* -------------------- TESTAB -------------------- */

/* -------------------- CONFIG -------------------- */
export const CONFIG_BANNER_HIRE = 'config_banner_hire'

/* ----------------- QUICK WINS ------------------ */

export const SQUAD_02_FIELD_ADJUSTMENT_BENEFITS = 'squad_02_field_adjustment_benefits'
