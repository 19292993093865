<template>
	<div>
		<NuxtLayout>
			<NuxtLoadingIndicator :height="1" />
			<NuxtPage />
		</NuxtLayout>

		<Broadcasting />
		<Commands v-if="$auth.loggedIn && $auth.user" />
		<UNotifications />
	</div>
</template>

<script setup>
import Broadcasting from '~/components/Broadcasting.vue'
import Commands from '~/components/commands.vue'

const route = useRoute()
const config = useRuntimeConfig()
const { $auth, $tracking, $feature, $repository } = useNuxtApp()
const { t: $t } = useI18n()

const loadingSurvey = ref(false)

useSeoMeta({
	title: $t('Inicio'),
})

const sendNpsSurvey = async () => {
	if (loadingSurvey.value) return
	if (!$auth.user?.surveys?.nps) return

	loadingSurvey.value = true

	const { success } = await $repository.v3.hire.auth.postSurveyShowed($auth.user.surveys.nps.id)
	if (success) {
		await $auth.fetchMe()
		$tracking.hotjar.npsFullProfile()
	}

	loadingSurvey.value = false
}

onMounted(() => {
	zendDesk.load(config.public.zendesk_key)
	$tracking.auth.identify()
	$tracking.page.view()
	$feature.setURL(window.location.href)
	sendNpsSurvey()
})

watch(
	() => route.fullPath,
	(_, oldValue) => {
		$tracking.storage.set('app', {
			previous_url: window.location.origin + oldValue,
		})
		$tracking.page.view()
		$feature.setURL(window.location.href)
	},
)

watch(
	() => route.path,
	() => {
		zendDesk.toggleRoute()
		sendNpsSurvey()
	},
)
</script>
